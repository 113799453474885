import { YellowButton } from "common/buttons";
import { HelveticaBold, HelveticaSemiBold, PoppinsSemiBold } from "common/typographies";
import React, {useRef, useState, useContext, useEffect} from "react";
import CanvasDraw from "react-canvas-draw";
import { useParams } from "react-router-dom";
import ActsContext from "context/actsContext/actsContext";
import { useUtils } from "hooks/useUtils";
import { CircularProgress } from "@mui/material";


const SignActComponent = () => {

    const canvasRef = useRef(null);
    const [imageCanvas, setImageCanvas] = useState(null);
    const {selectedActa, updateAct, generatePdf} = useContext(ActsContext)
    const {email} = useParams()
    const[actasParsed, setActasParsed] = useState({})
    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)


    useEffect(() => {
        if(selectedActa!=null){
            setActasParsed(selectedActa)
            let find = selectedActa.attendees.find(element => element.contact.email === email)
            console.log(find)
            if(find.hasSigned){
                setComplete(true)
            }
        }
    }, [selectedActa])

    const {generateFileUrl} = useUtils()

    async function convertCanvasToImage() {
        setLoading(true)
        setImageCanvas(canvasRef.current.getDataURL("image/png"))
        let auxBlob = dataURItoBlob(canvasRef.current.getDataURL("image/png"))
        let auxFile = new File([auxBlob], 'signature.png', {type: 'image/png'})
        let s3Url = await generateFileUrl(auxFile, 'public', selectedActa.accountId)
        let find = actasParsed.attendees.find(element => element.contact.email === email)
        find.signature = s3Url
        find.hasSigned = true
        await updateAct(actasParsed, actasParsed.project[0], actasParsed.accountId)
        await generatePdf(actasParsed.actId, email)
        setLoading(false)
        setComplete(true)
    }

    function dataURItoBlob(dataURI) {
        var binary = atob(dataURI.split(',')[1]);
        var array = [];
        for(var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        console.log('---------- si subida la imagen -------------', new Blob([new Uint8Array(array)], {type: 'image/png'}))
        return new Blob([new Uint8Array(array)], {type: 'image/png'});
    }


    return (
        <div style={{width:'100%', justifyContent:'center', height:'90vh', textAlign:'center', position:'relative'}}>
            <div style={{position:'absolute', top:'50%', transform:'translate(-50%, -50%)', left:'50%'}}>
                {!complete ? <>
                <HelveticaBold style={{fontSize:'20px', color:'#414141'}}>
                    Firmar Acta
                </HelveticaBold>
                <HelveticaSemiBold style={{fontSize:'14px'}}>
                    Dibuja sobre el lienzo para estampar tu firma
                </HelveticaSemiBold>
                <CanvasDraw 
                    ref={canvasRef}
                    lazyRadius={0}
                    brushRadius={1}
                    brushColor="#414141"
                    canvasWidth={400}
                    canvasHeight={300}
                    hideGrid={true}
                    style={{border:'1px solid #414141', borderRadius:'5px', marginTop:'20px'}}
                />
                {loading ? <div style={{marginTop:'40px'}}>
                   <CircularProgress size={24}/>
                  
                </div>
                :
                <div className="inline">
                <YellowButton
                    onClick={() => canvasRef.current.eraseAll()}
                    style={{marginTop:'40px', background:'white', border:'1px solid #ffd52b', marginRight:'20px'}}>
                    <PoppinsSemiBold style={{fontSize:'14px', color:'#414141', paddingTop:'12px'}}>
                        Borrar
                    </PoppinsSemiBold>
                </YellowButton>
                <YellowButton
                    onClick={() => {convertCanvasToImage()}}                
                    style={{marginTop:'42px'}}>
                    <PoppinsSemiBold style={{fontSize:'14px', color:'#414141', paddingTop:'12px'}}>
                        Firmar
                    </PoppinsSemiBold>
                </YellowButton>
                </div>
                }
                </>
                :
              <>
                 <HelveticaBold style={{fontSize:'20px', color:'#414141'}}>
                    Acta firmada correctamente
                </HelveticaBold>
                <YellowButton
                    onClick={() => {window.location.href = 'https://app.tribboo.co'}}                
                    style={{marginTop:'20px'}}>
                    <PoppinsSemiBold style={{fontSize:'14px', color:'#414141', paddingTop:'12px'}}>
                        Ir a Tribboo
                    </PoppinsSemiBold>
                </YellowButton>
              </>
                }
            </div>
        </div>
    )
    ;
}

export default SignActComponent;
