import styled from "styled-components";

export const YellowButton = styled.button`
  width: 186px;
  height: 48px;
  
  border: none;
  background: #ffd52b;
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #414141;
  transform: matrix(1, 0.01, -0.01, 1, 0, 0);

  &:hover {
    background: #ffd52b !important;
    opacity: 0.8;
  }

`;

export const RegisterOrLoginOrLogoutButton = styled.button`
  width: 186px;
  height: 48px;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.1));
  border: 1px solid #ffd52b;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #414141;
  transform: matrix(1, 0.01, -0.01, 1, 0, 0);
`;
