import React from "react"
import { Redirect } from "react-router-dom"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Register from "../pages/Authentication/Register"
import Dashboard from "../pages/Dashboard/index"
import SendBudgetAproach from "pages/sendBudgetAproach"
import SignAct from "pages/signAct"
// Dashboard

const authProtectedRoutes = [
  {path : "/dashboard", component: Dashboard},
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  {path: "/proposal/:requestIdentifier", component: SendBudgetAproach},
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  {path: "/portal-sign-act/:actId/:email", component: SignAct},

]

export { publicRoutes, authProtectedRoutes }
