import { useContext } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

import {
  RegisterOrLoginOrLogoutButton as RegisterButton,
  RegisterOrLoginOrLogoutButton as LoginButton,
  RegisterOrLoginOrLogoutButton as LogoutButton,
} from "common/buttons";
import { TitleDiv } from "common/containers";

import SettingsHeaderDropdown from "components/dropdowns/settingsHeaderDropdown";

import AuthContext from "context/auth/authContext";
import ProposalContext from "context/proposal/proposalContext";

const Header = ({ name }) => {
  const history = useHistory();

  const { user, statusRegister, logOut } = useContext(AuthContext);
  const { providerInfo } = useContext(ProposalContext);

  let button = null;

  if (user) {
    button = (
      <LogoutButton
        onClick={(e) => {
          e.preventDefault();
          logOut();
        }}
      >
        Cerrar Sesión
      </LogoutButton>
    );
  } else {
    if (statusRegister) {
      button = (
        <LoginButton onClick={() => history.push("/login")}>
          Iniciar Sesión
        </LoginButton>
      );
    } else {
      button = (
        <RegisterButton onClick={() => history.push("/register")}>
          Registrarme
        </RegisterButton>
      );
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height: "60px",
        display: "flex",
        paddingTop: "20px",
        marginLeft: "20px",
      }}
    >
      {name ? (
        <TitleDiv style={{ marginTop: "1px" }}>
          {providerInfo ? "Hola, " + providerInfo.completeName : ""}
        </TitleDiv>
      ) : (
        <TitleDiv style={{ marginTop: "1px" }}>
          Historial de presupuestos enviados
        </TitleDiv>
      )}

      <div>
        <AutorenewIcon
          style={{
            color: "#4461D7",
            width: "24px",
            marginTop: "2.5px",
            marginLeft: "5px",
          }}
        />
      </div>
      {name ? (
        <div
          style={{ marginLeft: "auto", marginTop: "0px", marginRight: "7px" }}
        >
          <SettingsHeaderDropdown />
        </div>
      ) : null}
      {name ? (
        <div
          style={{
            backgroundColor: "#FFE992",
            borderRadius: "20px",
            marginRight: "16px",
            width: "30px",
            height: "30px",
            marginTop: "0px",
          }}
        >
          <LightbulbOutlinedIcon
            sx={{
              paddingTop: "5px",
              paddingLeft: "5px",
              fontSize: "24px",
              stroke: "#FFE992",
              strokeWidth: "0.5",
            }}
          />
        </div>
      ) : (
        <div
          style={{
            marginLeft: "auto",
            backgroundColor: "#FFE992",
            borderRadius: "20px",
            marginRight: "16px",
            width: "30px",
            height: "30px",
            marginTop: "0px",
          }}
        >
          <LightbulbOutlinedIcon
            sx={{
              paddingTop: "5px",
              paddingLeft: "5px",
              fontSize: "24px",
              stroke: "#FFE992",
              strokeWidth: "0.5",
            }}
          />
        </div>
      )}

      <div style={{ marginRight: "40px", marginTop: "-10px" }}>{button}</div>
    </div>
  );
};

Header.propTypes = {
  name: PropTypes.string,
};

export default Header;
