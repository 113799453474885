import Header from "components/header/header";
import Proposal from "components/proposal";



const SendBudgetAproach = () => {
    
   return(
       <>
       <Header name={"Adri"}></Header>
       <Proposal></Proposal>
       </>
   )
}

export default SendBudgetAproach;