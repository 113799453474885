import {Auth, Storage} from 'aws-amplify'
import AuthContext from 'context/auth/authContext'


export const useUtils = () => {

    const isLocalhost = Boolean(
        window.location.hostname === "localhost" ||
          // [::1] is the IPv6 localhost address.
          window.location.hostname === "[::1]" ||
          window.location.hostname === "dev.app.tribboo.co" ||
          window.location.hostname === "qa.app.tribboo.co" ||
          window.location.hostname === "develop.app.tribboo.co",
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
      )
      async function generateFileUrl(file, level, accountId) {
        try {
          await Storage.put(file.name, file, {
            level : level? level : 'protected',
            contentType: file.type
          });
          const url = 'https://dev.storage.tribboo.co/public/' + file.name
          return url
        } catch (error) {
          console.log("Error uploading file: ", error);
        }
    }

   
    return {
        generateFileUrl
        
    }
}