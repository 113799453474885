import CloseIcon from "@mui/icons-material/Close";
import {Modal} from "@mui/material";
import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {Auth} from "aws-amplify";
import CheckIcon from '@mui/icons-material/Check';



function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '500px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalChangePassword = ({openPassword, setOpenPassword, setOpenVerification, setEmailCambio}) => {
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const refPasswordActual = useRef(null)
    const refPasswordNueva = useRef(null)
    const refPasswordRepita = useRef(null)

    const refErrorActual = useRef(null)
    const refErrorNueva = useRef(null)
    const refErrorRepita = useRef(null)
    var letras_mayusculas="ABCDEFGHYJKLMNÑOPQRSTUVWXYZ";
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    function isLowerCase(str){
        return str == str.toLowerCase() && str != str.toUpperCase();
    }

    const onClose = ()=> {
        setOpenPassword(false)
    }
    let validaContra ="true"
    function changePassword(){
       
            Auth.forgotPassword(contra.actual)

                .then(data => console.log('hola',data))

                .catch(err => console.log('no va',err));
                
                setEmailCambio(contra.actual)
                setOpenPassword(false)
                setOpenVerification(true)
    }


    const [contra, guardarContra] = useState({
        actual: '',
        nueva:'',
        repita:''
    });

    const obtenerContra = e => {
        guardarContra({
            ...contra,
            [e.target.name] : e.target.value
        })
    }

    const clickPassword =(e) =>{
    
        if(e.target.name=="actual"){
            refPasswordActual.current['id']="focus"
        }else if(e.target.name=="nueva"){
            refPasswordNueva.current['id']="focus"
        }
        else if(e.target.name=="repita"){
            refPasswordRepita.current['id']="focus"
        }
    }


    return (<>
        <Modal open={openPassword} onClose={onClose}>
            <div style={modalStyle} className={classes.paper}>
                <CloseIcon id="cerrar" onClick={onClose}></CloseIcon>
                <div id="tituloContra">Te enviaremos un codigo de verificación</div>
                <form id="Form">
                    <p className="Titulos">Email</p>
                    <div >
                        <input
                            type="text"
                            ref={refPasswordActual}
                            name="actual"
                            onClick={clickPassword}
                            //className="inputContra"
                            onChange={obtenerContra}></input>
                    </div>
                    <div ref={refErrorActual} id="errorMessagePassword">La contraseña introducida no es correcta</div>
                    

                    <div id="contraButton"><button type="button" onClick={changePassword}
                                                   className="AddContact">Confirmar</button>
                    </div>
                </form>
            </div>
        </Modal>
    </>
)
}


ModalChangePassword.propTypes = {
    openPassword: PropTypes.bool.isRequired,
    setOpenPassword: PropTypes.func.isRequired,
    setOpenVerification: PropTypes.func.isRequired,
    setEmailCambio: PropTypes.func.isRequired

}
export default withRouter(ModalChangePassword);