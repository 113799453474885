import styled from 'styled-components';

export const ChapterContainer = styled.div`
    width: 100% !important;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.10);
    border-radius: 0px;
    height: 44px;
    padding-top:0px;
    margin-bottom:0px;
    margin-top: 10px;
    background-color: #CCD8FC;
    border-radius: 6px;
`;
export const SubChapterContainer =  styled.div`
    width: 100% !important;
    box-shadow 0px 1px 0px 0px rgba(0, 0, 0, 0.10);
    border-radius: 0px;
    height: 44px;
    padding-top:0px;
    margin-bottom:3px;
    margin-top: 0px;
    background-color: #E9ECF8;
    border-radius: 6px;
`;

export const CenterDiv = styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
`;

export const ChapterPriceDiv = styled.div`
    width: 120px;
    height: 28px;
    border: 1px solid #4461d7;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0 4px 6px rgba(50,50,93,.11)) drop-shadow(0 1px 3px rgba(0,0,0,.08));
    filter: drop-shadow(0 4px 6px rgba(50,50,93,.11)) drop-shadow(0 1px 3px rgba(0,0,0,.08));
    border-radius: 6px;
    align-items: center;
    text-align: center;
    letter-spacing: .02em;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-top: 2px;
    background-color: #4461d7;
    color: #fff;
    font-size: 15px;
    margin-left: auto;
    margin-right: 22px;
    margin-top:7px;
`;

export const TitleDiv = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #414141;
`;

