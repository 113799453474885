import styled from 'styled-components';

export const HistoricTable = styled.table`
    width: 100%;
    border: none;
    th{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #414141;
        height:'45px';
    };
    tr{
        border-bottom: 0.5px solid #B7B7B7;
    };
    td{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        height:'45px';
    }
    td:first-child{
        padding-top:13px;
    }

`;
export const TableDiv =  styled.div`
    height: 45px;
`;

export const PartStyledTable = styled.table`
    width: 100%;  
    background-color: white;
    border: 1px solid #B7B7B7;
    margin-top:-2px;
    margin-left:0px;
    th{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        height:33px;
        color: #414141;
        border-left: 1px solid #B7B7B7;
        
    }
    th:first-child{
        border-left:none;
    }
    td{
        font-family: 'Roboto';
        height:33px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #414141;
        border-left: 1px solid #B7B7B7;
        padding-left: 33px;
        text-align: left;
    }
    td:first-child{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        height:33px;
        font-size: 12px;
        line-height: 16px;
        color: #B7B7B7;
        border-left: none;
    }
    tr{
        border-bottom:1px solid #E9ECEF;
    }
    tr:last-child{
        border-bottom:none;
    }
    tr:first-child{
        border-bottom: 1px solid #B7B7B7;
        
    }

`;