
import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      margin: 'auto',
      width: '70%',
      marginTop:'60px'
    },
    left: {
      padding: theme.spacing(2),
      textAlign: 'left',
    },
    right: {
        padding: theme.spacing(2),
        textAlign: 'right',
        
      },
  }));

