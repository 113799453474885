import styled from 'styled-components';

export const HelveticaBold = styled.p`
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    color: #A0AEC0;
`;
export const HelveticaSemiBold = styled.p`
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: #414141;
`;

export const PoppinsSemiBold = styled.p`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: #414141;
`


export const PoppinsGrey = styled.p`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    color: #A0AEC0;
`;

export const BlueInput = styled.p`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4461D7;
`;

export const ChapterTypo = styled.p`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #414141;
    margin-top: 13px;
    margin-left:14px;
`;