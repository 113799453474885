import React, { useEffect, useContext, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link , Redirect} from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import { useHistory} from 'react-router-dom';
import logoIcon from "../../assets/images/logo_small.png";
import frame1 from '../../assets/images/login/frame1.png';
import frame2 from '../../assets/images/login/frame2.png';
import frame3 from '../../assets/images/login/frame3.png';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { UncontrolledCarousel } from 'reactstrap';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import './Login.css'
import logoLarge from '../../assets/images/login/logo_large.png';
import linea from '../../assets/images/login/linea.png';
import lineaGris from '../../assets/images/login/lineaGris.png';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import { CircularProgress } from "@mui/material"


const Register = props => {
  const authContext = useContext(AuthContext);
  const [login, setLogin] = useState(false)
  const { Register, user, statusRegister, errorRegistro, errorType, Login } =
    authContext
  const [registrationError, setRegisterError] = useState(false);
  const history = useHistory();
  let valida = false;
  const refHide = useRef(null)
  const refShow = useRef(null)
  const handlePasswordShow = () => {
    refHide.current.id='visibilityOff'
    refShow.current.id='visibilityOn'
   setShowPass(true)
  }
  const handlePasswordHide = () => {
    refHide.current.id='visibilityOn'
    refShow.current.id='visibilityOff'
    setShowPass(false)
   }
   const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      textButton: "Registrar",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor introduce tu email"),
      password: Yup.string().required("Por favor introduce tu contraseña"),
    }),
    onSubmit: async values => {
      if(login==true){
        await Login(values)
      }else{
        await Register(values)
      }
      
    },
  })
  const [showPass, setShowPass] = useState (false)
  const refLet = useRef(null)
  const refTam = useRef(null)
  const refEsp = useRef(null)
  const refNum = useRef(null)
  const refLetOk = useRef(null)
  const refTamOk = useRef(null)
  const refEspOk = useRef(null)
  const refNumOk = useRef(null)
  const refLetNo = useRef(null)
  const refTamNo = useRef(null)
  const refEspNo = useRef(null)
  const refNumNo = useRef(null)
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  function isLowerCase(str){
    if(str.length==0){return true}else{
    return str == str.toLowerCase() && str != str.toUpperCase();}
}
useEffect(()=>{
  if(validation.values.password.length>=8){
    refTam.current.className='pRegisterOk'
    refTamOk.current.id='showAdvertOk'
    refTamNo.current.id='hideAdvertFalse'
  }else{
    refTam.current.className='pRegister'
    refTamOk.current.id='hideAdvertOk'
    refTamNo.current.id='showAdvertFalse'
  }
  if((validation.values.password).match(/\d+/g)==null){
      refNum.current.className='pRegister'
      refNumOk.current.id='hideAdvertOk'
      refNumNo.current.id='showAdvertFalse'
  }else{
    refNum.current.className='pRegisterOk'
    refNumOk.current.id='showAdvertOk'
    refNumNo.current.id='hideAdvertFalse'
  }
  if(isLowerCase(validation.values.password)){
    refLet.current.className='pRegister'
    refLetOk.current.id='hideAdvertOk'
    refLetNo.current.id='showAdvertFalse'
  }else{
    refLet.current.className='pRegisterOk'
    refLetOk.current.id='showAdvertOk'
    refLetNo.current.id='hideAdvertFalse'
  }
  if(format.test(validation.values.password)==false){
    refEsp.current.className='pRegister'
    refEspOk.current.id='hideAdvertOk'
    refEspNo.current.id='showAdvertFalse'
  }else{
    refEsp.current.className='pRegisterOk'
    refEspOk.current.id='showAdvertOk'
    refEspNo.current.id='hideAdvertFalse'
  }
  
},[validation.values.password])
const items = [
  {
    src: frame1,
    altText: 'Slide 1',
  },
  {
    src: frame2,
    altText: 'Slide 2',
  },
  {
    src:frame3,
    altText: 'Slide 3',
  }
];
  function gotoLogin(){
    history.push('/login')
  }
  useEffect(()=>{
    if(statusRegister==true)
    {  Login({
      email: validation.values.email,
      password: validation.values.password,
    })}

  },[statusRegister])


  useEffect(() => {
    setLoading(false)
  }, [ errorType, errorRegistro])
  

  const [loading, setLoading] = useState(false)

  return (
   
     <React.Fragment>
     {user && <Redirect to="/dashboard" />}
     <MetaTags>
       <title>Register | Tribboo</title>
     </MetaTags>
     <div className="home-btn  d-sm-block">
       <Link to="/" className="text-dark">
         <i className="fas fa-home h2" />
       </Link>
     </div>
     <div >
       <div id="princContainer">
       <div className="recuadroGeneralAuth">
         <div id='centerRecuadro'>
         <img src={logoLarge} style={{width:'150px', height:'46px'}}/>
         <div className="tituloAuth">¡Bienvenido!</div>
         <div className="subTitleAuth">
               <img src={linea} style={{width:'82px', marginRight:'10px', paddingTop:'1px'}}/>
               ¡Regístrate! 
               {registrationError ? (
                  <div
                    style={{
                      marginBottom: "-10px",
                      marginTop: "20px",
                      fontSize: "14px",
                      color: "red",
                    }}
                  >
                    {errorType}
                  </div>
                ) : null}
          </div>
                   <Form
                     className="form-horizontal"
                     onSubmit={e => {
                      setLoading(true)
                      validation.values.textButton = "Registrar"
                      e.preventDefault()
                      setRegisterError(false)
                      setLoading(true)
                      validation.values.email.length > 0 &&
                      validation.values.password.length > 0 ? (
                        <>
                          {(validation.values.textButton = "Registrando...")}
                          {setLoading(true)}
                          {validation.handleSubmit()}
                          {(validation.values.textButton = "Registrar")}
                          {setRegisterError(true)}
                        
                        </>
                      ) : (
                        setRegisterError(false)
                      )
                      return false
                    }}
                   >
                      
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                     <div className="mb-3" style={{marginTop:'-40px'}}>
                       <Label className="tituloForm">Email</Label>
                       <Input 
                         id='inputsLogin'
                         name="email"
                         className="form-control"
                         placeholder="Ingresa tu email aquí"
                         type="email"
                         onChange={validation.handleChange}
                         onBlur={validation.handleBlur}
                         value={validation.values.email || ""}
                         invalid={
                           validation.touched.email && validation.errors.email ? true : false
                         }
                       />
                       {validation.touched.email && validation.errors.email ? (
                  
                         <FormFeedback id='feedBack' type="invalid"><CancelOutlinedIcon id='errorAuth'/>{validation.errors.email}</FormFeedback>
                     
                       ) : null}
                     </div>

                     <div className="mb-3">
                       <Label className="tituloForm">Contraseña</Label>
                       <Input
                       id='inputsLogin'
                         name="password"
                         value={validation.values.password || ""}
                         type= {showPass==false ? "password" : "text"}
                         placeholder="Ingresa tu contraseña aquí"
                         onChange={validation.handleChange}
                         onBlur={validation.handleBlur}
                         invalid={
                           validation.touched.password && validation.errors.password ? true : false
                         }
                       />
                       <VisibilityOffIcon ref={refHide} id='visibilityOn' onClick = {handlePasswordShow}/>
                       <VisibilityIcon ref={refShow} id='visibilityOff' onClick= {handlePasswordHide}/>
                       {validation.touched.password && validation.errors.password ? (
                         <FormFeedback id='feedBack' type="invalid"><CancelOutlinedIcon id='errorAuth'/>{validation.errors.password}</FormFeedback>
                       ) : null}
                     </div>

                     <p id='Sourcesans'>
                       ¿Ya tienes una cuenta?{" "}
                  <Link id='Sourcesans' to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>

                <div className="mt-3 d-grid">
                  {loading==false ? 
                  <button className="buttonAuth" type="submit">
                    {validation.values.textButton}
                  </button> : <CircularProgress size={24} />}
                </div>
                <p id="Sourcesans" style={{marginBottom:'0px', marginTop:'20px'}}>
                  Continuando estás aceptando nuestros
                  <a
                    id="Sourcesans"
                    href="https://www.tribboo.co/terms-of-services"
                    target="_blank"
                    rel="noreferrer"
                    className="font-weight-medium text-primary"
                  >
                    {" "}
                   términos y condiciones
                  </a>{" "}
                </p>
                     
                   </Form>
                   <div style={{marginTop:'25px'}}>
                     <img src={lineaGris}/>
                   </div>
                   <div className='showOptionsPass' style={{paddingTop:'15px'}}>
                     La contraseña debe contener:
                     <div>
                       <div className="inline"> <CheckCircleOutlineSharpIcon ref={refLetOk} style={{width:'16px', color: '#3BA452'}}/>
                          <ErrorOutlineSharpIcon ref={refLetNo} style={{width:'16px', color: '#BF2929'}}/>
                        <p ref={refLet} className="pRegister">Mínimo una letra mayúscula</p> </div>
                       <div className="inline"> <CheckCircleOutlineSharpIcon ref={refTamOk} style={{width:'16px', color: '#3BA452'}}/>
                          <ErrorOutlineSharpIcon ref={refTamNo} style={{width:'16px', color: '#BF2929'}}/>
                         <p ref={refTam} className="pRegister"> Mínimo 8 carácteres</p> </div>
                       <div className="inline"> <CheckCircleOutlineSharpIcon ref={refNumOk} style={{width:'16px', color: '#3BA452'}}/>
                        <ErrorOutlineSharpIcon ref={refNumNo} style={{width:'16px', color: '#BF2929'}}/>
                       <p ref={refNum} className="pRegister">Mínimo un carácter númerico</p> </div>
                       <div className="inline"> <CheckCircleOutlineSharpIcon ref={refEspOk} style={{width:'16px', color: '#3BA452'}}/>
                       <ErrorOutlineSharpIcon ref={refEspNo} style={{width:'16px', color: '#BF2929'}}/>
                       <p ref={refEsp} className="pRegister">Mínimo una carácter especial</p> </div>
                     </div>
                   </div>
                   </div>
             
       </div>
       <div id="AquiCarousel">
       <UncontrolledCarousel id='CarouselNuevo' items={items} />
       </div>
       </div>
       
              
       </div>
   </React.Fragment>

  );
};

export default Register;
