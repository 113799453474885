import React,{Fragment, useRef, useState, useEffect, useContext} from 'react'
import propTypes from 'prop-types'
import {Modal, Box, Typography, IconButton, Grid, TextField, Autocomplete, TextareaAutosize, ToggleButton, CircularProgress, ToggleButtonGroup, Badge} from '@mui/material'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import PacoSendImage from 'assets/images/budgeting/Send.png'
import { NotoSansInput } from 'common/inputs';
import ProposalContext from 'context/proposal/proposalContext';
import {useParams} from 'react-router-dom'



const SendFirstStep = ({handleClose, message, setMessage}) => {
    const {requestIdentifier} = useParams();
    const handleChangeMessage = (event) => {
        event.preventDefault();
        setMessage(event.target.value);
      }
    const {userInfo, proposalInfo, sendAcceptedProposal, projectInfo, providerInfo} = useContext(ProposalContext)
    console.log(userInfo, proposalInfo, projectInfo, providerInfo)
    const [loading, setLoading] = useState(false)
    const sendInvitation = async (e) => {
      console.log(message)
        e.preventDefault()
        setLoading(true)
       await sendAcceptedProposal(proposalInfo.proposalId, proposalInfo.accountId, userInfo['email'], requestIdentifier,
        projectInfo.projectId, providerInfo)   
        setLoading(false)
       handleClose()
      }
    return (       
        <Grid container direction={"column"} marginTop={'0.5rem'}
              spacing={3} marginLeft={'-6px'} paddingRight={'10px'}
            >
              <Grid item marginLeft={'0rem'}>
                <IconButton sx={{
                  border: '1px solid #000001', 
                  height: '25px', 
                  width: '25px',
                  color: 'black',
                  marginBottom: '1rem'
                  }}
                  onClick={handleClose}
                >
                    <ArrowForwardIosRoundedIcon style={{width:'15px'}}/>
                </IconButton>
              </Grid>
              <Box>
                <img style={{marginLeft:'15px'}} src={PacoSendImage} alt="PacoSend" width="90%" height="auto"/>
              </Box>
              <Grid item>
                <p style={{fontFamily:'Poppins', fontWeight:'600', fontSize:'16px', color:'#414141', marginTop:'15px', marginBottom:'0px'}}>
                    Estás a punto de enviar esta  propuesta
                </p>

              </Grid>
                <Grid item>
                
              <Typography variant="body1" style={{fontFamily:'Poppins', fontWeight:'300', fontSize:'14px', color:'#414141', marginTop:'-15px', marginBottom:'0px'}}>
                        Recuerda que una vez que envies al cliente la
                        propuesta no podrás modificarla.
                    </Typography>
                
                    
                </Grid>
                
                
                <Grid item style={{paddingBottom:'0px'}}>
                    <Typography variant="body1"  align='left' style={{
                      fontFamily:'Poppins', 
                      fontWeight:'500',
                      fontSize:'14px',
                      color:'#414141', marginBottom:'0px'}}>
                        Nombre cliente o empresa:
                    </Typography>
                </Grid>
                <Grid item>
                <NotoSansInput style={{marginTop:'0px'}} value={userInfo['custom:nombre_empresa']} disabled='true'/>
                </Grid>
       
                <Grid item style={{paddingBottom:'0px'}}>
                    <Typography variant="body1"  align='left'  style={{
                      fontFamily:'Poppins', 
                      fontWeight:'500',
                      fontSize:'14px',
                      color:'#414141', marginBottom:'20px'}}>
                        Escribe el mensaje:
                    </Typography>
                </Grid>
                <Grid item mt={'-0.7rem'}>
            
            <TextareaAutosize 
              style={{width: '100%', height:'200px', marginTop: -20, fontFamily:'Noto sans', color:'#414141', fontWeight:'200', backgroundColor:'white', borderRadius:'8px', border: '1px solid #C4C4C4', paddingLeft:'5px'}}
              minRows={6}
              name='message'
              placeholder='Escribe aquí el mensaje que deseas enviar adjunto'
              value={message}
              onChange={handleChangeMessage}
            />
          </Grid>
              <Grid 
                  container  
                  alignItems={'center'} 
                  justifyContent={'center'}
                  marginTop={'3rem'}
                  marginBottom={'3rem'}
                >
                  {loading==false ? 
                      <button className='AddProject' onClick={(e) => {sendInvitation(e)}}
                      style={{marginTop:'0px', position:'inherit', marginLeft:'30px'}}
                      >
                        Enviar
                      </button>
                      :
                        <CircularProgress size={24}/>
                  }
                </Grid>
              </Grid>
    )
}

SendFirstStep.propTypes = {
    handleClose : propTypes.func,
    message: propTypes.string,
    setMessage: propTypes.func
}
export default SendFirstStep