import { useParams } from 'react-router-dom';
import ProposalContext from 'context/proposal/proposalContext';
import AproachInfo from "components/proposal/aproachInfo";
import React, { useLayoutEffect, useContext } from 'react';
import ProposalContent from './proposalContent';
import Comments from './comments';


const Proposal = () => {
     // M2E4NmJjMDEtMTdlOS00ZDJlLWJiZDYtNTFmNzUyZjQzYWI1OjFiNGFlYjE3LWI2ZmEtNGFmYi1hYjQ3LWI3N2MyNjFkMTdkMQ==
    const {requestIdentifier} = useParams();
    const proposalContext = useContext(ProposalContext);
    const {getProposalInfo, loading} = proposalContext;
    useLayoutEffect(() => {
        getProposalInfo(requestIdentifier);
    },[])
    return(
        
            loading ? 'Cargando...' : 
                <>
                 <AproachInfo/>
                 <ProposalContent/>
                 <Comments/>
                </>
            
         
        

        
    )
}

export default Proposal;