import { HistoricTable, TableDiv } from "common/tables"
import { Grid } from "@mui/material"
import Header from "components/header/header";
import noProposal from "assets/images/errors/noProposal.png"
const DashBoard = () => {

    return(
        <>
        <Header></Header>
        <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ marginTop:'200px' }}
      >
   <HistoricTable>
       <tr>
           <th style={{paddingLeft:'30px'}}>Nombre del proyecto</th>
           <th>Destinatario</th>
           <th style={{minWidth:'110px'}}>Tamaño</th>
           <th>Fecha Enviado</th>
       </tr>
       <tr>
           <TableDiv><td style={{paddingLeft:'30px'}}>Nombre Presupuesto 1</td> </TableDiv>
           <td>destinatario@contruccion.com</td>
           <td style={{color:'#C4C4C4'}}>125MB</td>
           <td style={{color:'#C4C4C4'}}>Sab, 32 Febrero 2025</td>
       </tr>
   </HistoricTable>
   {
       /*
        <img src={noProposal} style={{width:'343px', height:'312px'}}/>
       */
   }
  
   </Grid>
    </>
    )
}

export default DashBoard