import React, { Children } from "react";
import PropTypes from "prop-types";
import { PartStyledTable } from "common/tables";

const TableStructure = ({children}) => {
    return(
    <PartStyledTable>
        <tr>
            <th style={{minWidth:'81px'}}></th>
            <th style={{minWidth:'300px', width:'90%'}}></th>
            <th style={{minWidth:'88px'}}>Medición</th>
            <th style={{minWidth:'205'}} colspan='2'>Empresa Proveedora</th>
          
        </tr>
        <tr>
            <th style={{minWidth:'81px'}}>U</th>
            <th style={{minWidth:'300px', width:'90%', textAlign:'left', paddingLeft:'33px'}}>Descripción</th>
            <th style={{minWidth:'88px', textAlign:'left', paddingLeft:'33px'}}></th>
            <th style={{minWidth:'100px', textAlign:'left', paddingLeft:'33px'}}>€/u</th>
            <th style={{minWidth:'105px', textAlign:'left', paddingLeft:'33px'}}>Total</th>
       
        </tr>
        {children}
    </PartStyledTable>
    )
}

TableStructure.propTypes =  {
   children: PropTypes.node.isRequired
}

export default TableStructure;