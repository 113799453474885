

const actsReducer = (state, action) => {
  switch (action.type) {
    case 'GET_ACTA':
      state.selectedActa = action.payload[0]
      return { ...state }
    case 'UPDATE_ACT':
      const result = []
      for (const acta of state.acts) {
        if (action.payload.actId === acta.actId) {
          result.push(action.payload)
        } else {
          result.push(acta)
        }
      }
      state.acts = result
      return { ...state }
    default:
      return state
  }
}

export default actsReducer
