import React, { useState, useEffect, useContext } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';



const SettingsHeaderDropdown = props => {

  const [menu, setMenu] = useState(false)
 
  return (
    <>
    <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
         >
        <DropdownToggle
        id='settingb'
            style={{marginTop:'-10px', color:'black', margin:'0px', display:'contents'}}
        >
        <MoreHorizIcon id='settingsBud' style={{color:'#414141', backgroundColor:'white'}}/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag="e"  style={{cursor:'pointer'}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-send font-size-16 align-middle me-1"/>
           Enviar
          </DropdownItem>
          <DropdownItem tag="c" style={{cursor:'pointer'}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-down-arrow-circle font-size-16 align-middle me-1"/>
            Descargar
          </DropdownItem>
          <DropdownItem tag="a" style={{cursor:'pointer'}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-x-circle bx-rotate-90 font-size-16 align-middle me-1"/>
            Rechazar Propuesta
          </DropdownItem>
          <DropdownItem tag="e" style={{cursor:'pointer'}}>
            {" "}
            <i style={{marginTop:'-2px'}} className="bx bx-folder font-size-16 align-middle me-1"/>
            Histórico
          </DropdownItem>
     
        </DropdownMenu>
      </Dropdown>
  </>
  )
}

SettingsHeaderDropdown.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any

}

export default SettingsHeaderDropdown