import React, { useReducer } from "react"
import PropTypes from "prop-types"
import { API, Auth, a } from "aws-amplify"

import ActsContext from "./actsContext"
import actsReducer from "./actsReducer"


const ActsState = props => {
  const initialState = {
    acts: [],
    selectedActa: null,
  }

  const [state, dispatch] = useReducer(actsReducer, initialState)


  const getActa = async ActaId => {
    const apiName = "service-acts"
    const path = `portal/acts/getActById/${ActaId}`
    const res = await API.get(apiName, path)
    dispatch({ type: 'GET_ACTA', payload: res })
  }

  const updateAct = async (data, projectInfo, accountId) => {
    const apiName = "service-acts"
    const path = "portal/acts/updateAct"
    console.log('------------------', data)

    const newAttendants = []
    for (const attendant of data.attendees) {
      let aux = {...attendant.contact, hasSigned: attendant.hasSigned, signature: attendant.signature, contactid: attendant.contact.contactid, name: attendant.contact.completeName}
      newAttendants.push(aux)
    }

    const req = {
     
      body: {
        actId: data.actId,
        projectId: projectInfo.projectId,
        project: [{ name: projectInfo.name, projectId: projectInfo.projectId }],
        projectName: projectInfo.name,
        accountId: accountId,
        director: data.director,
        author: data.author,
        themes: data.themes,
        attendees: newAttendants,
        extras: data.extras,
      },
    }
    const res = await API.put(apiName, path, req)
    dispatch({ type: 'UPDATE_ACT', payload: res })
  }

  const generatePdf = async (actId, email) => {
    const apiName = "service-acts"
    const path = `portal/acts/generatePDF/${actId}`
    const req = {
      body:{
        email: email
      }
    }
    console.log('------------------', req)
      await API.post(apiName, path, req)


    
  }


  return (
    <ActsContext.Provider
      value={{
        acts: state.acts,
        selectedActa: state.selectedActa,
        getActa,
        updateAct,
        generatePdf
      }}
    >
      {props.children}
    </ActsContext.Provider>
  )
}

ActsState.propTypes = {
  children: PropTypes.element,
}

export default ActsState
