import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React,{useContext,  useEffect,  useState, useRef} from "react";
import AuthContext from "../../context/auth/authContext";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import {
  CircularProgress
} from '@mui/material'
import { withRouter, Link , Redirect} from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import logo from "../../assets/images/logo_small.png";
import googleicon from "../../assets/images/googleicon.png";
import ModalChangePassword from "./ModalChangePassword"
import ModalVerificationCode from "./ModalVerificationCode"
import { prototype } from "apexcharts";
import './Login.css'
import './Projects.css'
import logoLarge from '../../assets/images/login/logo_large.png';
import linea from '../../assets/images/login/linea.png';
import lineaGris from '../../assets/images/login/lineaGris.png';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import frame1 from '../../assets/images/login/frame1.png';
import frame2 from '../../assets/images/login/frame2.png';
import frame3 from '../../assets/images/login/frame3.png';
import { UncontrolledCarousel } from 'reactstrap';
import { style } from "@mui/system";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


const Login = props => {
const [showPass, setShowPass] = useState (false)
const items = [
  {
    src: frame1,
    altText: 'Slide 1',
  },
  {
    src: frame2,
    altText: 'Slide 2',
  },
  {
    src:frame3,
    altText: 'Slide 3',
  }
];

  const authContext = useContext(AuthContext);
  const [error, setError] =useState(null)
  const {Login, user, getUser, errorLogin} = authContext;
  const refError = useRef(null)
  useEffect(() => {
    getUser()
  } , [])
  const [loading, setLoading] = useState(false)
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      textInit: 'Iniciar Sesión',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor introuduzca su Email"),
      password: Yup.string().required("Por favor introduzca su Contraseña"),
    }),
    onSubmit: (values) => {
      console.log(values)
      Login(values);
      //console.log(result)

    }
  });
  const refHide = useRef(null)
  const refShow = useRef(null)
  console.log(errorLogin)
  const [openPassword, setOpenPassword] = useState(false);
  const handleAbrir = () => {
      setOpenPassword(true);
  }
  const [openVerification, setOpenVerification] = useState(false);
  const [emailCambio, setEmailCambio] = useState('')
  
  const handlePasswordShow = () => {
    refHide.current.id='visibilityOff'
    refShow.current.id='visibilityOn'
   setShowPass(true)
  }
  const handlePasswordHide = () => {
    refHide.current.id='visibilityOn'
    refShow.current.id='visibilityOff'
    setShowPass(false)
   }

   const [counter, setCounter]= useState(0)
   const [text, setText] = useState('Iniciar Sesión')
   useEffect(()=>{

    if(errorLogin.estado==true){ 
      setText('Inciar Sesión')
      setLoading(false)
    }
   },[counter, errorLogin.estado, errorLogin.mensaje])


  return (
  
    <React.Fragment>
      { user && <Redirect to="/dashboard" />
      }
      <MetaTags>
        <title>Login | Tribboo</title>
      </MetaTags>
      <div className="home-btn  d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div >
        <div id="princContainer">
        <div className="recuadroGeneralAuth">
          <div id='centerRecuadro'>
          <img src={logoLarge} style={{width:'150px', height:'46px'}}/>
          <div className="tituloAuth">¡Hola de vuelta!</div>
          <div className="subTitleAuth">
                <img src={linea} style={{width:'82px', marginRight:'10px', paddingTop:'1px'}}/>
                ¿Nos conocemos?
           </div>
                
           {errorLogin.estado==true ?  <>
                    
                <div ref={refError} id='errorLoginShow'>{errorLogin.mensaje}</div></>: null}
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                      
                        e.preventDefault();
                        validation.handleSubmit();
                        validation.values.textInit = "Entrando... "
                        setCounter(counter+1)
                        return false;
                      }}
                    >
                       {error ? <>
                      {validation.values.textInit = "Iniciar Sesión"}
                      {setText("Iniciar Sesión")}
                      <Alert color="danger">{error}</Alert>
                      </>
                      : null}

                      <div className="mb-3" style={{marginTop:'-40px'}}>
                        <Label className="tituloForm">Email</Label>
                        <Input 
                          id='inputsLogin'
                          name="email"
                          className="form-control"
                          placeholder="Ingresa tu email aquí"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                   
                          <FormFeedback id='feedBack' type="invalid"><CancelOutlinedIcon id='errorAuth'/>{validation.errors.email}</FormFeedback>
                      
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="tituloForm">Contraseña</Label>
                        <Input
                        id='inputsLogin'
                          name="password"
                          value={validation.values.password || ""}
                          type= {showPass==false ? "password" : "text"}
                          placeholder="Ingresa tu contraseña aquí"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        <VisibilityOffIcon ref={refHide} id='visibilityOn' onClick = {handlePasswordShow}/>
                        <VisibilityIcon ref={refShow} id='visibilityOff' onClick= {handlePasswordHide}/>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback id='feedBack' type="invalid"><CancelOutlinedIcon id='errorAuth'/>{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div onClick={handleAbrir} id="forgotPass2">¿Has olvidado la contraseña?</div>

                      <div className="inline-check">
                        <input
                          type="checkbox"
                          style={{width:'12px'}}
                          id="customControlInline"
                        />
                        <label
                          id='rememberAuth'
                          htmlFor="customControlInline"
                        >
                          Recuérdame
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        {loading==false ? 
                        <button
                          className="buttonAuth"
                          type="submit"
                          style={{cursor:"pointer"}}
                          onClick={()=>{setLoading(true); validation.handleSubmit();setCounter(counter+1);}}
                        >
                          {text}
                        </button>
                        :
                        <CircularProgress size={24} />}
                      </div>
                    </Form>
                    <div style={{marginTop:'25px'}}>
                      <img src={lineaGris}/>
                    </div>
                    <div className="solicitarAcceso">
                    Esto es una versión beta y de momento no puedes ingresar a la plataforma libremente. <p id='strongAcces'>Solicita una demo para tener acceso.</p>
                    </div>
                    </div>
        </div>
        <div id="AquiCarousel">
        <UncontrolledCarousel id='CarouselNuevo' items={items} />
        </div>
        </div>
        
                           {/*
                <p>
                  Don&#39;t have an account?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                */}
        </div>
        
        <ModalChangePassword openPassword={openPassword} setOpenPassword={setOpenPassword} setOpenVerification={setOpenVerification}
        setEmailCambio={setEmailCambio} 
        ></ModalChangePassword>
        <ModalVerificationCode openVerification={openVerification} setOpenVerification={setOpenVerification} emailCambio={emailCambio}></ModalVerificationCode>
    </React.Fragment>
    
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
