import { useReducer } from "react";
import ProposalReducer from "./proposalReducer";
import ProposalContext from "./proposalContext";
import {API} from '@aws-amplify/api';
import {
    ON_GET_PROPOSAL,
    GET_PROPOSAL_SUCCESS,
    GET_PROPOSAL_ERROR,
    UPDATE_PART
} from "../types";

class PartProposal {
    constructor(part) {
        Object.assign(this, part);
    }
    update(part) {
        Object.assign(this, part);
    }

}
class ChapterProposal {
    constructor(chapter, isRoot) {
        if (isRoot === true){
            this.chapterProposalId = 'root';
            this.subchapters = chapter.map(ch => new ChapterProposal(ch, false));
        }
        else{
            let {parts, subchapters, ...info } = chapter;
            Object.assign(this, info);
            this.subchapters = subchapters.map(subchapter => new ChapterProposal(subchapter, false));
            this.parts = parts.map(part => new PartProposal(part));
        }
    }

    *preOrder() {
        yield this;
        if(this.subchapters.length > 0){
            for (let subchapter of this.subchapters) {
                yield* subchapter.preOrder();
            }
        }
    }
    updatePart(chapterId, partId, partInfo){
        for (let subchapter of this.preOrder()) {
            if (subchapter.chapterProposalId === chapterId){
                for (let part of subchapter.parts) {
                    if (part.partProposalId === partId){
                        part.update(partInfo);
                    }
                }
            }
        }
        this.updateTotal()
    }
    updateTotal = () => {
        if(this.parts){
            if(this.parts.length>0){
                this.total = this.parts.reduce((total, part) => total + (part.providerPrice*part.partInfo.quantity), 0)
            }    
            else{
                this.total = 0
                for (let chapter of this.subchapters){
                    chapter.updateTotal()
                    this.total = this.total + chapter.total
                }
            }
        }
        else{
            this.total = 0
            for (let chapter of this.subchapters){
                chapter.updateTotal()
                this.total = this.total + chapter.total
            }
        }
        console.log(this)
    }

    
}
const ProposalState = (props) => {
    const ENDPOINT = 'dev-TRIBOO-API'
    const initialState = {
        loading: true,
        error: null,
        projectInfo: null,
        providerInfo: null,
        userInfo: null,
        proposalInfo: null,
        proposalTree: null
    }
    const [state, dispatch] = useReducer(ProposalReducer, initialState);
    const getProposalInfo = async (requestIdentifier) => {
        try {
            dispatch({
                type: ON_GET_PROPOSAL
            })
            
            const res = await API.get(ENDPOINT, `/api/proposal/${requestIdentifier}`);
            const proposalTree =new ChapterProposal(res.proposalTree, true);
            proposalTree.updateTotal()
            dispatch({
                type: GET_PROPOSAL_SUCCESS,
                payload: {
                    proposalTree : proposalTree,
                    proposalInfo: res.proposalInfo,
                    projectInfo: res.projectInfo,
                    providerInfo: res.providerInfo,
                    userInfo: res.userInfo
                }
            })
        }
        catch (err) {
            console.log(err);
            dispatch({
                type: GET_PROPOSAL_ERROR
            })
        }
    }

    const updateProposalPart = async(data) => {
        console.log(data)
        try {
            const body = {
                body:data
            }
            const res = await API.put(ENDPOINT, `/api/proposal/part`, body);
            dispatch({
                type: UPDATE_PART,
                payload: {
                    chapterId: data.chapterProposalId,
                    partId: data.partProposalId,
                    res: data
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }


    const sendAcceptedProposal = async(proposalId, accountId, email, requestIdentifier, projectId, contactInfo) => {
        const ENDPOINT2 = 'dev-PROVIDERS-API'
        try {
            const body = {
                proposalId:proposalId,
                accountId:accountId,
                email:email,
                requestIdentifier:requestIdentifier,
                projectId:projectId,
                contactInfo:contactInfo
            }
            console.log(body)
            const res = await API.post(ENDPOINT2, `/sendProposalToClient`, {body:body});
        }
        catch (err) {
            console.log(err)
        }
    }



    return (
        <ProposalContext.Provider

            value={{
                loading: state.loading,
                proposalInfo: state.proposalInfo,
                error: state.error,
                getProposalInfo,
                proposalTree : state.proposalTree,
                projectInfo: state.projectInfo,
                providerInfo: state.providerInfo,
                userInfo: state.userInfo,
                updateProposalPart,
                sendAcceptedProposal
            }}
        >
            {props.children}
        
        </ProposalContext.Provider>
    )

}

export default ProposalState;