import styled from 'styled-components';

export const NotoSansInput = styled.input`
width: 385.13px;
height: 40px;
border: 1px solid #B7B7B7;
box-sizing: border-box;
filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.08));
border-radius: 6px;
font-family: 'Noto Sans';
font-style: normal;
font-weight: 300;
color: #414141;
margin-bottom: 9px;
padding-left:8px;

&::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #B7B7B7;
}

&:focus {
    outline: none !important;
    border:1px solid #FFD52B;
}


`;

export const PriceInput = styled.input`
    background-color:white;
    border:none;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #414141;

    &::placeholder {
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #B7B7B7;
    }
`;

