import Grid from '@material-ui/core/Grid';
import { useStyles } from 'common/layoutStyles';
import ProposalContext from 'context/proposal/proposalContext';
import { useContext, useEffect} from 'react';
import Chapter from './chapter';


const ProposalContent = () => {
    const classes = useStyles();
    const {proposalTree, loading} = useContext(ProposalContext)
    useEffect(()=>{
        console.log('aksjdbkasbdkajbdkas')
    },[proposalTree])
    return(
        <div className={classes.root} >
        <Grid style={{minHeight:'200px'}}>
            {loading  ? 'Cargando...' 
             : 
            proposalTree.subchapters.map((capitulo, index) => (
                <Chapter 
                    key = {capitulo.chapterProposalId}
                    capitulo = {capitulo}
                    numero = {index+1}
                    soySub = {false}
                    margin =  {0}
                />
            ))
            }
        </Grid>
       </div>
       )
}

export default ProposalContent