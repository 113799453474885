import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import { PriceInput } from "common/inputs";
import ProposalContext from "context/proposal/proposalContext";

const Part = ({part}) =>{
    const [price, setPrice] = useState(null)
    const [total, setTotal] = useState(0)
    const {updateProposalPart} = useContext(ProposalContext)
    const [partCopy, setPartCopy] = useState({})
    useEffect(()=>{
        if(part.providerPrice!=0){
            setPrice(part.providerPrice)
            setTotal(parseFloat(parseFloat(part.partInfo.quantity)*part.providerPrice).toFixed(2))
        }
        setPartCopy(part)
    },[])

    const handleOnChange = (e) => {
        e.preventDefault()
        if(e.target.value<0){
           setPrice(e.target.value*-1)
           setPartCopy(
            {
                ...partCopy,
                providerPrice: parseFloat(e.target.value*-1)
            }
            )
           setTotal(parseFloat(parseFloat(part.partInfo.quantity)*e.target.value*-1).toFixed(2))
        }else{
            setPrice(e.target.value)
            setPartCopy(
                {
                    ...partCopy,
                    providerPrice: parseFloat(e.target.value)
                }
                )
            setTotal(parseFloat(parseFloat(part.partInfo.quantity)*e.target.value).toFixed(2))
        }
    }

    const handleOnBlur = () =>{
       updateProposalPart(partCopy)
    }

    return(
        <tr>
            <td>{part.partInfo.unity}</td>
            <td >{part.partInfo.description}</td>
            <td style={{paddingLeft:'23px'}}>{part.partInfo.quantity}</td>
            <td style={{fontFamily:'Noto sans'}}>
                <PriceInput style={{width:'100%'}}
                    placeholder='Precio'
                    type='number'
                    min="0"
                    value={price}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                >
                </PriceInput>
            </td>
            <td>
                {total==0 ? '' : total}
            </td>
        </tr>
    )
}
Part.propTypes = {
    part : PropTypes.object.isRequired
}
export default Part;