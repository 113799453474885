import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './assets/fonts.css'
import Amplify,{API} from "aws-amplify"
import { Switch} from "react-router-dom"
import { BrowserRouter as Router } from "react-router-dom"
import { authProtectedRoutes, publicRoutes } from "./routes"
import AuthState from "./context/auth/authState"
import Authmiddleware from "./routes/route"
import NonAuthLayout from "./components/NonAuthLayout"
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.3.0";
import "./assets/demo/demo.css";
import "../node_modules/perfect-scrollbar/css/perfect-scrollbar.css";
import "./assets/scss/theme.scss"
import ProposalState from "context/proposal/proposalState"
import ActsState from 'context/actsContext/actsState';

const isLocalhost = Boolean(
  window.location.hostname === "localhost",
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
)

Amplify.configure({
  aws_user_files_s3_bucket: "tribboo-app-dev",
  aws_user_files_s3_bucket_region: "eu-west-1",
  Auth: {
    region : 'eu-west-1',
    userPoolId : 'eu-west-1_PTNcSS6YQ',
    userPoolWebClientId : '3gfegqa433pssvf862cetll5ts',
    identityPoolId : "eu-west-1:94d474f4-eb61-4e38-8c67-977a21f0fb1a",
    mandatorySignIn : false,
    oauth: {
      domain: "tribboo.auth.eu-west-1.amazoncognito.com",
      scope: ["email", "openid", "profile","aws.cognito.signin.user.admin"],
      redirectSignIn: 'https://providers.tribboo.co',
      redirectSignOut: 'https://providers.tribboo.co',
      responseType: "code"
    }
  },
  API: {
    endpoints: [
      {
        name: "dev-TRIBOO-API",
        endpoint: "https://api.dev.tribboo.co/legacy"
      },
      {
        name: 'dev-PROVIDERS-API',
        endpoint:"https://api.dev.tribboo.co/providers"
      },
      {
        name: "dev-Tribboo-Search",
        endpoint: "https://s4mxbp71kf.execute-api.eu-west-1.amazonaws.com/dev"
      },
      {
        name: 'prod-Tribboo-Search',
        endpoint: 'https://x84fwcob15.execute-api.eu-west-1.amazonaws.com/prod'
      },
      {
        name: 'service-acts',
        endpoint: isLocalhost
        ? "https://api.dev.tribboo.co/acts/"
        : "https://api.tribboo.co/acts/"
      },
    ]
  },
})


const app = (

    <AuthState>
    <ProposalState>
      <ActsState>
      <Router>
        <Switch>
        {publicRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    exact
                  />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact
                  />
                ))}
        </Switch>
      </Router>
      </ActsState>
    </ProposalState>
    </AuthState>
)

  ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
