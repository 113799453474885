import React from "react";
import PropTypes from "prop-types";
import TableStructure from "./tableStructure";
import Part from "./part";

const PartTable = (parts) => {

    return(
        <TableStructure>
            {
            parts.parts.map((part, index)=>(
                <Part key={part.partInfo.partidaId} part={part}/>
            ))
            }
        </TableStructure>
    )
}
PartTable.propTypes  = {
    parts: PropTypes.array.isRequired
}
export default PartTable;