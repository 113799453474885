//AUTH TYPES
export const SUCCES_REGISTER='SUCCES_REGISTER';
export const ERROR_REGISTER='ERROR_REGISTER';
export const LOGIN_SUCCESS='LOGIN_SUCCESS';
export const LOGIN_ERROR='LOGIN_ERROR';
export const LOGOUT='LOGOUT';
export const GET_USER='GET_USER';
export const MODIFICAR_USUARIO='MODIFICAR_USUARIO';
export const GET_USER_ERROR='GET_USER_ERROR';
export const USER_ERROR='USER_ERROR';


//PROPOSAL TYPES

export const ON_GET_PROPOSAL='ON_GET_PROPOSAL';
export const GET_PROPOSAL_SUCCESS='GET_PROPOSAL_SUCCESS';
export const GET_PROPOSAL_ERROR='GET_PROPOSAL_ERROR';
export const UPDATE_PART='UPDATE_PART';