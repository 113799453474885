import React, {Fragment, useEffect, useContext} from "react"
import { MetaTags } from "react-meta-tags"
import { Container } from "reactstrap"
import SignActComponent from "components/canvasSign"
import ActsContext from "context/actsContext/actsContext"
import {useParams} from "react-router-dom"


const SignAct = () => {

    const {getActa} = useContext(ActsContext)
    const {actId} = useParams()
    useEffect(() => {
        getActa(actId)
    }, [])


    return(
        <Fragment>
            <div>
                <MetaTags>
                    <title>Tribboo | Firma</title>
                </MetaTags>
                <Container fluid>
                  <SignActComponent/>
                </Container>
            </div>
        </Fragment>
    )
}
export default SignAct