import React, {useContext, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import { BlueInput, HelveticaBold, PoppinsGrey, PoppinsSemiBold } from '../../common/typographies';
import { NotoSansInput } from '../../common/inputs';
import { useStyles } from 'common/layoutStyles';
import ProposalContext from 'context/proposal/proposalContext';


const AproachInfo = () => {
    const classes = useStyles();
    const {proposalTree, projectInfo, providerInfo, userInfo, proposalInfo} = useContext(ProposalContext)
    
    const [formAttributes, setFormAttributes] =  useState({
        name:'',
        date:'',
        providerOrganization:''
    })

    const handleOnChange = (e) => {
        setFormAttributes(
            {
                ...formAttributes,
                [e.target.name] : [e.target.value]
            }
        )
    }
    console.log(formAttributes)
    return(
        <div className={classes.root} >
       <Grid container spacing={6}>
             <Grid item xs={8} className={classes.left} style={{paddingRight:'0px'}}>
                <HelveticaBold style={{marginTop:'16.5px', marginBottom:'5px'}}>Nombre de la compañia</HelveticaBold>
                <PoppinsSemiBold style={{marginBottom:'12px', marginTop:'0px'}}>{userInfo['custom:nombre_empresa']}</PoppinsSemiBold>
                <hr style={{width:'40%', margin:'0', color:'#B7B7B7', border:'0.5px solid #B7B7B7'}}/>
                <HelveticaBold style={{marginTop:'16.5px', marginBottom:'5px'}}>Dirección de obra</HelveticaBold>
                <PoppinsSemiBold style={{marginBottom:'12px', marginTop:'0px'}}>{projectInfo.orgAddress}</PoppinsSemiBold>
                <hr style={{width:'40%', margin:'0', color:'#B7B7B7', border:'0.5px solid #B7B7B7'}}/>
                <PoppinsGrey style={{marginTop:'16.5px', marginBottom:'5px'}}>Contacto</PoppinsGrey>
                <PoppinsSemiBold style={{marginBottom:'12px', marginTop:'0px'}}>{userInfo['custom:nombre_apellido']}</PoppinsSemiBold>
                <hr style={{width:'40%', margin:'0', color:'#B7B7B7', border:'0.5px solid #B7B7B7'}}/>
                <PoppinsGrey style={{marginTop:'16.5px', marginBottom:'5px'}}>NIF</PoppinsGrey>
                <PoppinsSemiBold style={{marginBottom:'12px', marginTop:'0px'}}>{userInfo['custom:nif']}</PoppinsSemiBold>
            </Grid>
            <Grid item xs={4} className={classes.left} style={{paddingLeft:'0px'}}>
                <Container style={{paddingTop:'10px', paddingLeft:'0px'}}>
                <BlueInput style={{marginBottom:'8px'}}>Nombre</BlueInput>
                <NotoSansInput placeholder='Introduce tu nombre'
                                value={formAttributes.name}
                                name='name'
                                onChange={handleOnChange}
                />
                
                <BlueInput style={{marginBottom:'8px'}}>Fecha</BlueInput>
                <NotoSansInput placeholder='Introduce la fecha de hoy'
                   value={formAttributes.date}
                   name='date'
                   onChange={handleOnChange}/>
              
                <BlueInput style={{marginBottom:'8px'}}>Empresa proveedora</BlueInput>
                <NotoSansInput placeholder='Introduce el nombre de la empresa proveedora'
                   value={formAttributes.providerOrganization}
                   name='providerOrganization'
                   onChange={handleOnChange}/>
                </Container>

            </Grid>
       </Grid>
       </div>
    )
}

export default AproachInfo;