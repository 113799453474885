import { useReducer } from "react";
import { Auth, API } from 'aws-amplify';
import AuthReducer from "./authReducer";
import AuthContext from "./authContext";
import React from 'react'
import PropTypes from 'prop-types';

import { 
    ERROR_REGISTER,
    SUCCES_REGISTER,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    GET_USER,
    USER_ERROR,
    GET_USER_ERROR
  } from "../types";

  const AuthState = props => {
    const initialState = {
      isAuthenticated: false,
      user: null,
      statusRegister: false,
      errorLogin: {estado : false , mensaje : null},
      errorRegistro: {estado : false , mensaje : null},
      waiting: true,
      errorType: "",
    }
  
    const [state, dispatch] = useReducer(AuthReducer, initialState);
  
    const Register = async data => {
      try {
        const {password, email} = data;
        const user = await Auth.signUp(
          {
            username: email,
            password: password,
            attributes: {
              email: email
            }
          }
        );
        dispatch({
          type: SUCCES_REGISTER,
          payload: user
        })
      } catch (error) {
        console.log(error);
        dispatch({
          type: ERROR_REGISTER,
          payload: error
        })
      }
    }
    const Login = async data =>{
      try {
    
        const res = await Auth.signIn(data.email, data.password)
        localStorage.setItem('userAuth', JSON.stringify(res.attributes));
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res
        })
        //return res
      } catch (error) {
        console.log(error)
        dispatch({
          type: LOGIN_ERROR,
          payload: error,
        })
        //return error
      }
    }
    const getUser = async () =>{
      try{
        const res =  await Auth.currentAuthenticatedUser()
        const res1 = await Auth.currentUserInfo()
        localStorage.setItem('userAuth', JSON.stringify(res.attributes));
        
        
        dispatch({
          type: GET_USER, 
          payload: res
        })
        
      }
      catch(error){
        console.log(error);
        dispatch({type: USER_ERROR})
      }
    }
  
    const logOut = async () =>{
      try{
        await Auth.signOut();
        localStorage.removeItem('userAuth');
        dispatch({
          type: LOGOUT
        })
      }
      catch(error){
  
      };
    }

  
    return (
      <AuthContext.Provider
        value={{
          user: state.user,
          isAuthenticated: state.isAuthenticated,
          statusRegister: state.statusRegister,
          waiting: state.waiting,
          Register,
          Login,
          getUser,
          logOut,
          errorLogin: state.errorLogin,
          errorRegistro: state.errorRegistro,
          errorType: state.errorType,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    )
  }
  
  AuthState.propTypes = {
    children: PropTypes.any
  }
  
  export default AuthState;