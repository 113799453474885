import React, {useContext, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import { BlueInput } from 'common/typographies';
import { useStyles } from 'common/layoutStyles';
import '../../assets/css/customInputs.css'
import { YellowButton } from 'common/buttons';
import { CenterDiv } from 'common/containers';
import SendProposalModal from 'components/modals/send/sendProposalModal';

const Comments = () => {
    const classes = useStyles();
    const [comment, setComment]= useState('')
    const handleChangeComment = (e) =>{
        setComment(e.target.value)
    }
    const [open, setOpen] = useState(false)
    return(
        <div className={classes.root} style={{marginTop:'48px'}}>
        <Grid>
            <BlueInput style={{marginBottom:'8px'}}>Comentarios</BlueInput>
            <p style={{display:'block', textAlign:'left', marginBottom: '0px', height:'100%'}}> <span className="textareaExpand" role="textbox" 
                                  autoComplete="off"
                                  name='resumen'
                                 onInput={handleChangeComment}
                            
                      contentEditable
                      >
                {comment}
            </span></p>
            <CenterDiv style={{marginTop:'50px', marginBottom:'50px'}}>
            <YellowButton onClick={()=>{setOpen(true)}}>Enviar Propuesta</YellowButton>
            </CenterDiv>
        </Grid>
        <SendProposalModal open={open} setOpen={setOpen} />
        </div>
    )
}

export default Comments;