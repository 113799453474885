import React,{useContext, useEffect, useRef} from 'react'
import {Modal, Box, Typography, IconButton, Grid, TextField, Autocomplete, TextareaAutosize, ToggleButton, CircularProgress, ToggleButtonGroup, Badge} from '@mui/material'
import propTypes from 'prop-types'
import SendFirstStep from './sendFirstStep';


const SendModal = {
    position: 'absolute',
    top: '0%',
    right: '0%',
    height: '100%',
    width: '425px',
    backgroundColor: 'white',
    paddingRight: '0px',
    borderRadius: '16px 0px 0px 16px',
    overflowY: 'scroll',
} 
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
TabPanel.propTypes = {
    children: propTypes.node,
    index: propTypes.any.isRequired,
    value: propTypes.any.isRequired,
}
const SendProposalModal = ({open, setOpen}) => {
 
    const [value, setValue] = React.useState(0);

    
    const handleClose = () => {
        setOpen(false);
        setValue(0);
    }
    
    const [message, setMessage] = React.useState('');

    return (
      <>
        <Modal
            open={open}
            onClose={() => {setOpen(false)}}
        >
            <Box 
                style={SendModal}
                component="form"
            >
            <TabPanel value={value} index={0}>
                <SendFirstStep handleClose={handleClose} message={message} setMessage={setMessage} />
            </TabPanel>
            </Box>
   
        </Modal>
        
      </>
    )
}
SendProposalModal.propTypes = {
    open: propTypes.bool.isRequired,
    setOpen: propTypes.func.isRequired,

}
export default SendProposalModal