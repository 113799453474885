import {
    ERROR_REGISTER,
    SUCCES_REGISTER,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    GET_USER,
    MODIFICAR_USUARIO,
    USER_ERROR
  } from '../types'
  
  const AuthReducer = (state, action) => {
    switch (action.type) {
      case SUCCES_REGISTER:
        return {
          ...state,
          statusRegister: true,
          errorRegistro: {estado : false , mensaje : null},
          waiting: false,
        isAuthenticated: true,
        errorType: "",
        }
      case ERROR_REGISTER:
        let aux
      if (
        action.payload.code == "UserLambdaValidationException" ||
        action.payload.code == "UsernameExistsException"
      ) {
        aux = "Ya existe una cuenta con este correo asociado"
      } else if (
        action.payload.code == "InvalidPasswordException" ||
        action.payload.code == "InvalidParameterException"
      ) {
        aux = "La contraseña no cumple los criterios específicados"
      } else {
        aux = ""
      }
        return {
          ...state,
          statusRegister: false,
          errorRegistro: {estado : true , mensaje : "Error al crear cuenta"},
          waiting: false,
          isAuthenticated: false,
          errorType: aux,
        }
      case LOGIN_SUCCESS:
        return {
          ...state,
          user : action.payload.attributes,
          errorLogin: {estado: false, mensaje: null},
          errorType:"",
        }
      case LOGIN_ERROR:
        let aux2
      if (action.payload.code == "NotAuthorizedException") {
        aux2 =
          "Ha excedido el número de intentos, por favor inténtelo de nuevo más tarde"
      } else {
        aux2 = "Usuario o contraseña incorrectos"
      }
        return {
          ...state,
          errorLogin: { estado: true, mensaje: aux2 },
        }
      case LOGOUT:
        return {
          ...state,
          user : null,
        }
      case GET_USER:
        return {
          ...state,
          user : action.payload.attributes,
        }
      case MODIFICAR_USUARIO:
        return {
          ...state,
          user : action.payload.attributes,
        }
      case USER_ERROR:
        return {
          ...state,
          statusRegister: false,
          errorRegistro: {estado : false , mensaje : null}
        }
      default:
        return state
    }
  }
  
  export default AuthReducer;