import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ChapterContainer, SubChapterContainer, ChapterPriceDiv } from "common/containers";
import { ChapterTypo } from "common/typographies";
import PartTable from "./part";
import Connector from '../../assets/images/budgeting/Connector.png';

const Chapter = ({capitulo, numero, soySub, margin}) => {
    console.log(capitulo)
    useEffect(()=>{
        console.log(capitulo.total)
    },[capitulo.total])
    return(
       soySub===false ?
        <> 
        <ChapterContainer>
            <tr  style={{marginTop: '0px'}}>
                <td style={{minWidth:'37px', paddingLeft: margin+'px'}}>
                    <ChapterTypo style={{ marginRight: '0px'}}>{numero}.</ChapterTypo>
                </td>
                <td style={{minWidth:'300px'}}>
                    <ChapterTypo style={{marginLeft:'0px'}}>{capitulo.chapterInfo.name}</ChapterTypo>
                </td>
                <td style={{width:'100%', textAlign:'right'}}>
                   <ChapterPriceDiv>{capitulo.total}€</ChapterPriceDiv>
                </td>
            </tr> 
        </ChapterContainer>
        {
            capitulo.subchapters.length > 0 ?
                capitulo.subchapters.map((subchapter, index) => (
                    <Chapter capitulo = {subchapter} numero={numero.toString()+'.'+(index+1)} soySub={true} margin={margin+12}/>
                ))
            :
            <PartTable parts={capitulo.parts}/>
        }
        </>
        :
        <>
        
        <SubChapterContainer>
             <tr style={{marginTop: '0px'}}>
                <td style={{minWidth:'37px', paddingLeft: margin+'px'}}>
                    <img src={Connector} style={{marginTop:'-16.5px', width:'30px', marginLeft:'0px'}}></img> 
                </td>
                <td>
                <ChapterTypo style={{ marginRight: '0px'}}>{numero}.</ChapterTypo>
                </td>
                <td style={{minWidth:'300px'}}>
                    <ChapterTypo>{capitulo.chapterInfo.name}</ChapterTypo>
                </td>
                <td style={{width:'100%', textAlign:'right'}}>
                   <ChapterPriceDiv>{capitulo.total}€</ChapterPriceDiv>
                </td>
            </tr> 
        </SubChapterContainer>
        {
            capitulo.subchapters.length > 0 ?
                capitulo.subchapters.map((subchapter, index) => (
                    <Chapter capitulo = {subchapter} numero={numero.toString()+'.'+(index+1)} soySub={true}  margin={margin+24}/>
                ))
            :
            <PartTable parts={capitulo.parts}/>
        }
        </>
    )
}

Chapter.propTypes = {
    capitulo : PropTypes.object.isRequired,
    numero : PropTypes.string.isRequired,
    soySub : PropTypes.bool.isRequired,
    margin : PropTypes.number.isRequired
};

export default Chapter;